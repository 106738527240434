import { Text } from "@mantine/core";
import { themeColor, PriceDisplay, PriceFluctuation } from ".";

type MarketPrice = {
  code: string;
  top_bid: {
    order_price: string;
    units: number;
  };
  top_ask: {
    order_price: string;
    units: number;
  };
  latest_price: string;
};

export function MarketPriceDisplay({
  code,
  top_ask,
  top_bid,
  latest_price,
}: MarketPrice) {
  return (
    <>
      <Text
        className="flex items-center pl-3 rounded-l-md py-1.5"
        color={themeColor["ecn-mc-light"]}
        size={12}
        weight="bolder"
      >
        {code}
      </Text>
      <PriceDisplay
        title="TOP BID"
        className="px-2 text-right py-1.5"
        price={top_bid.order_price ?? ""}
        volume={top_bid.units ?? ""}
      />
      <PriceDisplay
        title="TOP ASK"
        className="px-2 text-right py-1.5"
        price={top_ask.order_price ?? ""}
        volume={top_ask.units ?? ""}
      />
      <PriceFluctuation
        percentage_price={+latest_price ?? 1 / 100}
        price={latest_price ?? ""}
      />
    </>
  );
}
