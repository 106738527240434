import {
  FundingsAndWithdrawalsDataType,
  FundingsAndWithdrawalsType,
  MarketMonitoringDataType,
} from "@/sample/queries";
import { useWebSocket } from "@/hooks/utils";
import { ActionIcon, Drawer, Group, Stack, Text } from "@mantine/core";
import { ExportSquare, ReceiveSquare } from "iconsax-react";
import { Dispatch, SetStateAction } from "react";
import {
  themeColor,
  MatchedOrdersTable,
  FundingsTable,
  WithdrawalsTable,
  Skeletal,
} from ".";

type MarketDrawerProps = {
  isDrawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  matchedOrders: MarketMonitoringDataType["matched_orders"] | undefined;
  matchedOrdersLoading: boolean;
};

export function MarketDrawer({
  isDrawerOpen,
  setDrawerOpen,
  matchedOrders,
  matchedOrdersLoading,
}: MarketDrawerProps) {
  const {
    data: fundingsAndWithdrawals,
    isLoading: fundingsAndWithdrawalsLoading,
  } = useWebSocket<FundingsAndWithdrawalsType, FundingsAndWithdrawalsDataType>({
    key: ["fundings", "withdrawals"],
    pathname: `fundings_and_withdrawals`,
    queryFn: ({ messages }) => messages,
  });

  return (
    <Drawer
      withinPortal
      withCloseButton={false}
      withOverlay
      opened={isDrawerOpen}
      position="bottom"
      onClose={() => setDrawerOpen(false)}
      overlayColor="white"
      classNames={{
        drawer: "grid grid-cols-3 gap-6 bg-ecn-dim-red",
      }}
      overlayOpacity={0.55}
      overlayBlur={3}
      padding="xl"
      size="md"
    >
      <Stack className="overflow-auto" spacing="xs">
        <Group p="xs" position="apart" className="bg-ecn-light-red">
          <Text>Matched Orders</Text>
          <ActionIcon className="text-ecn-dark" size={20}>
            <ExportSquare variant="Linear" />
          </ActionIcon>
        </Group>
        <Skeletal
          className="flex-1 overflow-scroll"
          visible={matchedOrdersLoading}
        >
          <MatchedOrdersTable value={matchedOrders} />
        </Skeletal>
      </Stack>
      <Stack className="overflow-auto" spacing="xs">
        <Group p="xs" position="apart" className="bg-ecn-light-red">
          <Text>Fundings</Text>
          <ActionIcon className="text-ecn-dark" size={20}>
            <ExportSquare variant="Linear" />
          </ActionIcon>
        </Group>
        <Skeletal
          className="overflow-scroll"
          visible={fundingsAndWithdrawalsLoading}
        >
          <FundingsTable value={fundingsAndWithdrawals?.fundings} />
        </Skeletal>
      </Stack>
      <Stack className="overflow-auto" spacing="xs">
        <Group p="xs" position="apart" className="bg-ecn-light-red">
          <Text>Withdrawals</Text>
          <ActionIcon className="text-ecn-dark" size={20}>
            <ExportSquare variant="Linear" />
          </ActionIcon>
        </Group>
        <Skeletal
          className="overflow-scroll"
          visible={fundingsAndWithdrawalsLoading}
        >
          <WithdrawalsTable value={fundingsAndWithdrawals?.withdrawals} />
        </Skeletal>
      </Stack>

      <div
        onClick={() => {
          setDrawerOpen((o) => !o);
        }}
        className="absolute right-0 flex items-center gap-3 px-5 py-2 ml-auto text-white cursor-pointer -top-12 rounded-tl-xl bg-accent-90 group"
      >
        <p>Collapse</p>
        <ActionIcon className="text-current group-hover:text-ecn-light-red group-hover:bg-transparent">
          <ReceiveSquare variant="Broken" />
        </ActionIcon>
      </div>
    </Drawer>
  );
}
