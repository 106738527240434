import { Group, Stack, Text } from "@mantine/core";
import { _numberFormatter } from "@/utils/lib";
import { ArrowRight2, ArrowUp } from "iconsax-react";
import { themeColor } from ".";

export function PriceFluctuation({ percentage_price, price }) {
  const percentage_color =
    percentage_price > 0
      ? themeColor["ecn-mc-green"]
      : themeColor["ecn-mc-red"];
  return (
    <Group className="px-3 py-1.5 rounded-r-md" spacing="xs" position="right">
      <Stack spacing={2}>
        <Text weight="bold" size={12}>
          {_numberFormatter(price)}
        </Text>
        <Group spacing={2}>
          <Text color={themeColor["ecn-mc-green"]} size={11}>
            {percentage_price}%
          </Text>
          <span color={percentage_color}>
            <ArrowUp size={14} color={percentage_color} variant="Outline" />
          </span>
        </Group>
      </Stack>
      <ArrowRight2 size={14} variant="Outline" />
    </Group>
  );
}
