import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

export function ChartType() {
  const { query } = useRouter();
  
  return (
    <section className="flex items-center gap-3">
      {[
        {
          name: "Trading View",
          value: "Trading",
        },
        {
          name: "Depth",
          value: "Depth",
        },
      ].map(({ name, value }) => (
        <Link key={value} href={{ query: { ...query, view: value } }} passHref>
          <a>
            <button
              className={clsx(
                "rounded-lg px-3 py-2 text-sm hover:bg-ecn-gray/70",
                value === query.view && "bg-ecn-gray text-ecn-dark font-bold"
              )}
            >
              {name}
            </button>
          </a>
        </Link>
      ))}
    </section>
  );
}