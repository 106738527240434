import { Group, Text } from "@mantine/core";
import { themeColor } from ".";

export function TopPortion({
  Icon,
  color = themeColor["ecn-mc-dark"],
  variant = "Outline",
  title,
}) {
  return (
    <Group spacing={6}>
      <Icon size={16} color={color} variant={variant} />
      <Text size="xs" color={themeColor["ecn-mc-light"]}>
        {title}
      </Text>
    </Group>
  );
}