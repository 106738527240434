import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

export function BoardsMenu({ boards }) {
  const { query } = useRouter();
  return (
    <div className="relative overflow-auto bg-ecn-light-red py-3 h-[60px]">
      <div className="absolute top-0 bottom-0 flex items-center gap-3 text-ecn-light px-2.5">
        {boards.map(({ name }) => (
          <Link key={name} href={{ query: { ...query, board: name } }} passHref>
            <a>
              <button
                className={clsx(
                  "rounded-lg px-3 py-2 text-sm hover:bg-ecn-gray/70",
                  name === query.board && "bg-ecn-gray text-ecn-dark font-bold",
                  "whitespace-nowrap"
                )}
              >
                {name}
              </button>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
}
