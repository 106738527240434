import { useMemo } from "react";
import { Column } from "react-table";
import { MarketMonitoringDataType } from "@/sample/queries";
import { _dateFormatter, _numberFormatter } from "@/utils/lib";
import { useMakeTable } from "@/components";

import dayjs from "dayjs";

type MatchedOrders = MarketMonitoringDataType["matched_orders"][number];
interface MatchedOrdersColumnType extends MatchedOrders {}

const matchedOrdersColumns: Array<Column<MatchedOrdersColumnType>> = [
  {
    Header: "Seller",
    accessor: "seller_cid",
  },
  {
    Header: "Buyer",
    accessor: "buyer_cid",
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ row }) => <>{_numberFormatter(row.original.price, "")}</>,
  },
  {
    Header: "Amount",
    accessor: "matched_qty",
    Cell: ({ row }) => <>{_numberFormatter(row.original.matched_qty, "")}</>,
  },
  {
    Header: "Time",
    accessor: "date",
    Cell: ({ row }) => <>{dayjs(row.original.date).format("h:mm a")}</>,
  },
];

export function MatchedOrdersTable({ value }) {
  function dataFilterer(rawData: MatchedOrders[]) {
    return rawData ?? [];
  }

  const data = useMemo(() => dataFilterer(value), [value]);
  const columns = useMemo(() => matchedOrdersColumns, []);

  const { Table: MatchedOrdersTable } = useMakeTable({
    columns,
    data,
  });

  return (
    <MatchedOrdersTable className="h-full overflow-auto bg-ecn-light-red">
      <MatchedOrdersTable.Head className="sticky top-0 bg-ecn-light-red text-ecn-dark" />
      <MatchedOrdersTable.Body className="overflow-auto text-ecn-light" />
    </MatchedOrdersTable>
  );
}
