import {
  MarketMonitoringDataType,
  SecurityMonitoringDataType,
} from "@/sample/queries";
import { clsx, Divider } from "@mantine/core";
import { _numberFormatter } from "@/utils/lib";
import { Lock } from "iconsax-react";
import { useRouter } from "next/router";
import { TopPortion, PriceChanges } from ".";

export function HeaderSection({
  securityInfo,
  marketInfo,
}: {
  securityInfo: SecurityMonitoringDataType;
  marketInfo:
    | MarketMonitoringDataType
    | {
        lowest_price: number;
        highest_price: number;
        total_value: number;
        total_volume: number;
      };
}) {
  const { query } = useRouter();

  const { highest_price, lowest_price, total_value, total_volume } = marketInfo;
  const { latest_price, security_type, board_name, closing_price } =
    securityInfo;

  const price_change = (+latest_price ?? 0 / +closing_price ?? 0) - 1;
  const highest_price_change = (+highest_price ?? 0 / +closing_price ?? 0) - 1;
  const lowest_price_change = (+lowest_price ?? 0 / +closing_price ?? 0) - 1;

  const priceChanges = {
    change: {
      price: +latest_price ?? 0, // yesterdays's closing price - the day before's closing price
      percentage_change: price_change * 100,
    },
    high: {
      price: +highest_price,
      percentage_change: highest_price_change * 100,
    },
    low: {
      price: +lowest_price,
      percentage_change: lowest_price_change * 100,
    },
    volume: {
      price: +total_volume,
    },
    value: {
      price: +total_value,
    },
  };

  return (
    <header className="flex flex-wrap flex-1 px-4 bg-ecn-light-red">
      <div className="flex items-center gap-6 pr-20">
        <h1 className="text-4xl font-extrabold">
          {security_type || board_name}/{query.security}
        </h1>
        <Divider orientation="vertical" className="my-auto h-1/2" />
        <section className="grid">
          <TopPortion Icon={Lock} title="Per Unit" />
          <h2
            className={clsx(
              latest_price < closing_price
                ? "text-ecn-fresh-red"
                : "text-ecn-fresh-green",
              "text-2xl text-ecn-red font-[700]"
            )}
          >
            {_numberFormatter(latest_price ?? 0)}
          </h2>
        </section>
      </div>
      <PriceChanges details={priceChanges} />
    </header>
  );
}
