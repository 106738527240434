import { SVGProps } from "react";

export function BuyTabIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H10V26H4C1.79086 26 0 24.2091 0 22V4Z"
        fill="#32C973"
      />
      <path
        d="M12 0H18C20.2091 0 22 1.79086 22 4V7.33333H12V0Z"
        fill="currentColor"
      />
      <rect
        x="12"
        y="9.33334"
        width="10"
        height="7.33333"
        fill="currentColor"
      />
      <path
        d="M12 18.6667H22V22C22 24.2091 20.2091 26 18 26H12V18.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}