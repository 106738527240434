import { Column } from "react-table";
import { useMemo } from "react";
import { FundingsAndWithdrawalsDataType } from "@/sample/queries";
import { _dateFormatter, _numberFormatter } from "@/utils/lib";
import { useMakeTable } from "@/components";

import dayjs from "dayjs";

type Withdrawals = FundingsAndWithdrawalsDataType["withdrawals"][number];
interface WithdrawalsColumnType extends Withdrawals {}

const withdrawalsColumns: Array<Column<WithdrawalsColumnType>> = [
  {
    Header: "Client",
    accessor: "client_name",
  },
  {
    Header: "ID",
    accessor: "cid",
  },
  {
    Header: "Amount",
    accessor: "amount_withdrawing",
    Cell: ({ row }) => <>{_numberFormatter(row.original.amount_withdrawing, "")}</>,
  },
  {
    Header: "Time",
    accessor: "time",
    Cell: ({ row }) => <>{dayjs(row.original.time).format("h:mm a")}</>,
  },
];

export function WithdrawalsTable({ value }) {
  function dataFilterer(rawData: Withdrawals[]) {
    return rawData ?? [];
  }

  const data = useMemo(() => dataFilterer(value), [value]);
  const columns = useMemo(() => withdrawalsColumns, []);

  const { Table: WithdrawalsTable } = useMakeTable({
    columns,
    data,
  });

  return (
    <WithdrawalsTable className="h-full overflow-auto bg-ecn-light-red">
      <WithdrawalsTable.Head className="sticky top-0 bg-ecn-light-red text-ecn-dark" />
      <WithdrawalsTable.Body className="overflow-auto text-ecn-light" />
    </WithdrawalsTable>
  );
}
