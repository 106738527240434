import { useMemo } from "react";
import { Column } from "react-table";
import { _dateFormatter, _numberFormatter } from "@/utils/lib";
import { useMakeTable } from "@/components";
import { css } from "@emotion/css";
import { themeColor } from ".";

import clsx from "clsx";

const sellOrdersColumns: Array<
  Column<{
    tid: string;
    cid: string;
    units: number;
    order_price: string;
    total_worth: string;
    status: string;
    created: string;
    updated: string;
  }>
> = [
  {
    Header: "Trade ID",
    accessor: "tid",
  },
  {
    Header: "Client ID",
    accessor: "cid",
  },
  {
    Header: "Price",
    accessor: "order_price",
    Cell: ({ row }) => <>{_numberFormatter(row.original.order_price)}</>,
  },
  {
    Header: "Amount",
    accessor: "units",
  },
  {
    Header: "Total",
    accessor: "total_worth",
    Cell: ({ row }) => <>{_numberFormatter(row.original.total_worth)}</>,
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Created",
    accessor: "created",
    Cell: ({ row }) => <>{_dateFormatter(row.original.created)}</>,
  },
  {
    Header: "Updated",
    accessor: "updated",
    Cell: ({ row }) => <>{_dateFormatter(row.original.updated)}</>,
  },
];

export function SellOrdersTable({ value }) {
  const data = useMemo(() => value ?? [], [value]);
  const columns = useMemo(() => sellOrdersColumns, []);

  const { Table: SellOrdersTable } = useMakeTable({
    data,
    columns,
    initialState: {
      hiddenColumns: ["tid", "cid", "status", "created", "updated"],
    },
  });

  return (
    <div className="grid content-start overflow-auto bg-ecn-light-red">
      <SellOrdersTable
        className={clsx(
          "h-full overflow-auto bg-ecn-light-red",
          css({
            "& td:first-of-type": {
              color: themeColor["ecn-mc-red"],
            },
          })
        )}
      >
        <SellOrdersTable.Head
          className="sticky top-0 z-[5] bg-ecn-light-red"
          classNames={{
            th: "!text-ecn-light",
          }}
        />
        <SellOrdersTable.Body className="overflow-auto text-ecn-low-dark" />
      </SellOrdersTable>
    </div>
  );
}
