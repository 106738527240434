import { useRouter } from "next/router";
import {
  Skeletal,
  HeaderSection,
  SecuritiesMenu,
  BoardsMenu,
  MainContent,
} from ".";
import { useGetBoardList, useWebSocket } from "@/hooks/utils";
import {
  MarketMonitoringType,
  SecurityMonitoringDataType,
} from "@/sample/queries";
import { useMemo } from "react";

export function ContentWrapper() {
  const router = useRouter();
  const { security } = router.query as Record<string, string>;

  // BOARDS INFORMATION
  const { boardListData, boardListLoading } = useGetBoardList();
  const boards = useMemo(
    () => [
      { code: "all", name: "All" },
      ...Object.values(boardListData?.data ?? []),
    ],
    [boardListLoading]
  );

  // SECURITY INFORMATION
  const { data: securities, isLoading: securitiesLoading } = useWebSocket<
    Array<SecurityMonitoringDataType>
  >({
    key: ["securities"],
    pathname: "securities",
  });

  const securityInfo: SecurityMonitoringDataType = useMemo(() => {
    const securityByName =
      (securities ?? [])?.reduce(
        (acc, data) => ((acc[data.code] = data), acc),
        {}
      ) ?? {};
    return securityByName[security] ?? {};
  }, [securitiesLoading, security]);

  // MARKET INFORMATION
  const { data: marketInfoData, isLoading: marketInfoLoading } =
    useWebSocket<MarketMonitoringType>({
      key: [security],
      pathname: `${security}/open-trades`,
      options: {
        enabled: !!security,
      },
    });

  const marketInfo = useMemo(
    () => marketInfoData?.messages,
    [marketInfoLoading]
  );

  function TopLeft() {
    return (
      <section className="grid gap-2">
        <div className="flex items-center justify-between px-2.5 py-3 text-sm bg-ecn-light-red">
          <h4 className="text-ecn-dark">MARKET PRICE</h4>
          <p className="font-bold text-ecn-light">24hr</p>
        </div>
        <Skeletal visible={boardListLoading}>
          {boards.length > 1 && <BoardsMenu boards={boards} />}
        </Skeletal>
      </section>
    );
  }

  function TopRight() {
    return (
      <Skeletal
        className="flex"
        visible={securitiesLoading || marketInfoLoading}
      >
        <HeaderSection
          securityInfo={securityInfo}
          marketInfo={
            marketInfo ?? {
              lowest_price: 0,
              highest_price: 0,
              total_value: 0,
              total_volume: 0,
            }
          }
        />
      </Skeletal>
    );
  }

  function BottomLeft() {
    return (
      <Skeletal
        className="flex min-w-full overflow-auto w-max bg-ecn-light-red"
        visible={boardListLoading || securitiesLoading}
      >
        <SecuritiesMenu securities={securities} />
      </Skeletal>
    );
  }

  return (
    <article className="grid overflow-auto flex-1 transition-all duration-300 grid-cols-[minmax(355px,_auto)_1fr] grid-rows-[auto_1fr] bg-ecn-dim-red gap-2 pt-2">
      <TopLeft />
      {security ? <TopRight /> : <div />}
      <BottomLeft />
      {security ? (
        <MainContent
          marketInfo={marketInfo}
          marketInfoLoading={marketInfoLoading}
          securitiesLoading={securitiesLoading}
          securityInfo={securityInfo}
        />
      ) : (
        <div />
      )}
    </article>
  );
}
