import { useEffect, useMemo, useRef, useState } from "react";

/**
 * EMA Indicator
 * The exponential moving average (EMA) is a technical chart indicator that
 * tracks the price of an investment (like a stock or commodity) over time.
 */
import { ema } from "react-financial-charts";

/**
 * Elder Ray Index
 * ===============
 * Elder that measures the amount of buying and selling pressure in a market.
 * This indicator consists of two indicators known as "bull power" and "bear power,"
 * which are derived from a 13-period exponential moving average (EMA).
 */
import { elderRay } from "react-financial-charts";

/**
 * Discontinuous Time-Scale Provider
 * =================================
 * This is used to create a linear scale that  defines one or more domain ranges, which
 * should be excluded from the scale, creating discontinuities in the rendered axis.
 */
import {
  discontinuousTimeScaleProviderBuilder,
  defaultScaleProvider,
} from "react-financial-charts";

/**
 * @link https://github.com/d3/d3-format
 * @example
 * d3.format(".0%")(0.123);  // rounded percentage, "12%"
 * d3.format("($.2f")(-3.5); // localized fixed-point currency, "(£3.50)"
 * d3.format("+20")(42);     // space-filled and signed, "                 +42"
 * d3.format(".^20")(42);    // dot-filled and centered, ".........42........."
 * d3.format(".2s")(42e6);   // SI-prefix with two significant digits, "42M"
 * d3.format("#x")(48879);   // prefixed lowercase hexadecimal, "0xbeef"
 * d3.format(",.2r")(4223);  // grouped thousands with two significant digits, "4,200"
 */
import { format } from "d3";

/**
 * A time formatter and parser inspired by strftime and strptime, used to parse
 * or format dates in various locale-specific representations.
 * @link https://github.com/d3/d3-time-format
 */
import { timeFormat } from "d3";

import {
  Chart,
  ChartCanvas,
  CurrentCoordinate,

  // TOOLTIPS
  MovingAverageTooltip,
  OHLCTooltip,
  SingleValueTooltip,

  // CHART TYPES
  BarSeries,
  CandlestickSeries,
  VolumeProfileSeries,
  ElderRaySeries,
  LineSeries,

  // CALCULATORS
  change,
  last,

  // INDICATORS
  EdgeIndicator,
  lastVisibleItemBasedZoomAnchor,
  mouseBasedZoomAnchor,
  YAxis,
  XAxis,
  CrossHairCursor,
  MouseCoordinateX,
  MouseCoordinateY,
  ZoomButtons,
} from "react-financial-charts";

import AutoSizer from "react-virtualized-auto-sizer";
import { StockChart } from "./stockChart";
import dayjs from "dayjs";
import { useViewportSize } from "@mantine/hooks";
import { useRouter } from "next/router";

interface IOHLCData {
  date: Date;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}

interface FinancialChartProps {
  data: IOHLCData[];
  height: number;
  dateTimeFormat?: string;
  margin?: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
  width: number;
  ratio: number;
}

const pricesDisplayFormat = format(",~f");
const tooltipDisplayFormat = format(",.2f");

// function emaVariableSize(windowSize: number, id: number) {
//   return ema()
//     .id(id)
//     .options({ windowSize })
//     .merge((d: any, c: any) => {
//       d[`ema${windowSize}`] = c;
//     })
//     .accessor((d: any) => d[`ema${windowSize}`]);
// }

function FinancialChart({
  data: initialData,
  dateTimeFormat = "%e %b",
  margin = {
    left: 0,
    right:
      Math.max(
        ...initialData
          .flatMap((v) => Object.values(v))
          .filter((v) => typeof v === "number")
      ).toString().length * 14,
    top: 0,
    bottom: 24,
  },
  height,
  width,
  ratio,
}: Readonly<FinancialChartProps>) {
  const xScaleProvider =
    discontinuousTimeScaleProviderBuilder().inputDateAccessor(
      (d: IOHLCData) => d.date
    );

  // CONTAINER DIMENSIONS
  const gridHeight = height - margin.top - margin.bottom;

  // DIVIDER DIMENSIONS
  const dividerHeight = 8;

  // BAR-CHART DIMENSIONS
  const barChartHeight = height / 3.5;
  const barChartOrigin = (_: number, h: number) => [0, h - barChartHeight];

  // CHART DIMENSIONS
  const chartHeight = gridHeight - barChartHeight - dividerHeight;

  // CHART EXTENTS
  const barChartExtents = (data: IOHLCData) => data.volume;
  const candleChartExtents = (data: IOHLCData) => [data.high, data.low];

  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(initialData);

  const max = xAccessor(data[data.length - 1]);
  const min = xAccessor(data[Math.max(0, data.length - 100)]);
  const xExtents = [min, max + 5];

  const timeDisplayFormat = timeFormat(dateTimeFormat);
  const volumeColor = (data: IOHLCData) => {
    return data.close > data.open
      ? "rgb(50 201 115 / 0.6)"
      : "rgb(234 49 44 / 0.6)";
  };
  const volumeSeries = (data: IOHLCData) => data.volume;
  const yEdgeIndicator = (data: IOHLCData) => data.close;
  const openCloseColor = (data: IOHLCData) => {
    return data.close > data.open ? "#32C973" : "#EA312C";
  };

  return (
    <ChartCanvas
      height={height}
      ratio={ratio}
      width={width}
      margin={margin}
      data={data}
      displayXAccessor={displayXAccessor}
      seriesName="Data"
      xScale={xScale}
      xAccessor={xAccessor}
      xExtents={xExtents}
      zoomAnchor={mouseBasedZoomAnchor}
      className="select-none"
    >
      <Chart
        id={3}
        height={chartHeight}
        yExtents={candleChartExtents}
        padding={{ top: 50, bottom: 10 }}
      >
        <XAxis
          showGridLines
          showDomain={false}
          showTicks={false}
          showTickLabel={false}
        />
        <YAxis
          showGridLines
          showDomain={false}
          showTicks={false}
          tickFormat={pricesDisplayFormat}
        />
        <CandlestickSeries fill={openCloseColor} />
        <MouseCoordinateY
          rectWidth={margin.right}
          displayFormat={tooltipDisplayFormat}
        />
        <EdgeIndicator
          itemType="last"
          rectWidth={margin.right}
          fill={openCloseColor}
          lineStroke={openCloseColor}
          displayFormat={pricesDisplayFormat}
          yAccessor={yEdgeIndicator}
        />
        <OHLCTooltip ohlcFormat={tooltipDisplayFormat} origin={[8, 16]} />
      </Chart>
      <rect
        fill="#f8f3f3"
        transform={`translate(0, ${chartHeight})`}
        width={width}
        height="8"
      />
      <Chart
        id={2}
        height={barChartHeight}
        origin={barChartOrigin}
        yExtents={barChartExtents}
        padding={{ top: 8, bottom: 10 }}
      >
        <XAxis
          showDomain={false}
          showTicks={false}
          showGridLines
          gridLinesStrokeStyle="#e0e3eb"
        />
        <YAxis
          showDomain={false}
          showGridLines
          showTicks={false}
          tickFormat={pricesDisplayFormat}
        />
        <ZoomButtons
          fill="#FFF"
          textFill="rgba(70, 70, 70, 0.8)"
          strokeWidth={2}
          fillOpacity={0.82}
          heightFromBase={barChartHeight / 3}
        />
        <MouseCoordinateX displayFormat={timeDisplayFormat} />
        <MouseCoordinateY
          rectWidth={margin.right}
          displayFormat={tooltipDisplayFormat}
        />

        <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />

        <SingleValueTooltip
          className="select-none"
          yAccessor={volumeSeries}
          yLabel="Volume"
          yDisplayFormat={tooltipDisplayFormat}
          origin={[8, 16]}
        />
      </Chart>
      <CrossHairCursor />
    </ChartCanvas>
  );
}

export function VolumeChart({ initialData, width, ratio }) {
  // const changeCalculator = change();
  // const calculatedData = changeCalculator(initialData);

  const xScaleProvider =
    discontinuousTimeScaleProviderBuilder().inputDateAccessor(
      (d: any) => d.date
    );
  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(initialData);

  const start = xAccessor(last(data));
  const end = xAccessor(data[Math.max(0, data.length - 150)]);
  const xExtents = [start, end];

  const volumeBarExtents = (data) => data.volume;

  return (
    <ChartCanvas
      height={400}
      width={width}
      ratio={ratio}
      seriesName="Volume Chart"
      data={data}
      xScale={xScale}
      xAccessor={xAccessor}
      displayXAccessor={displayXAccessor}
      xExtents={xExtents}
    >
      <Chart id={9} yExtents={volumeBarExtents}>
        <VolumeProfileSeries />
      </Chart>
    </ChartCanvas>
  );
}

// export function DepthChart({
//   data: initialData,
//   dateTimeFormat = "%d %b",
//   margin,
//   height,
//   width,
//   ratio,
// }: Readonly<FinancialChartProps>) {
//   const xScaleProvider =
//     discontinuousTimeScaleProviderBuilder().inputDateAccessor(
//       (d: IOHLCData) => d.date
//     );

//   // CONTAINER DIMENSIONS
//   const gridHeight = height - margin.top - margin.bottom;

//   // BAR-CHART DIMENSIONS
//   const barChartHeight = 100;
//   const barChartOrigin = (_: number, h: number) => [0, h - barChartHeight];

//   // CHART DIMENSIONS
//   const chartHeight = gridHeight - barChartHeight;

//   // CHART EXTENTS
//   const barChartExtents = (data: IOHLCData) => data.volume;
//   const candleChartExtents = (data: IOHLCData) => [data.high, data.low];

//   const { data, xScale, xAccessor, displayXAccessor } =
//     xScaleProvider(initialData);

//   const max = xAccessor(data[data.length - 1]);
//   const min = xAccessor(data[Math.max(0, data.length - 100)]);
//   const xExtents = [min, max + 5];

//   const timeDisplayFormat = timeFormat(dateTimeFormat);
//   const volumeColor = (data: IOHLCData) => {
//     return data.close > data.open ? "#32C973" : "#EA312C";
//   };
//   const volumeSeries = (data: IOHLCData) => data.volume;
//   const openCloseColor = (data: IOHLCData) => {
//     return data.close > data.open ? "#32C973" : "#EA312C";
//   };

//   return (
//     <>
//       <ChartCanvas
//         ratio={1}
//         width={width / 2 - 10}
//         height={height}
//         margin={{ left: 5, right: 0, top: 5, bottom: 30 }}
//         seriesName="MSFT"
//         data={buyOrdersSorted}
//         xAccessor={(d) => d.price}
//         xExtents={xExtentsBuy}
//         xScale={scaleLinear()}
//       >
//         <Chart id={10} yExtents={yAxisRange} width={width / 2}>
//           <Gradient
//             id="buyGradient"
//             color1="#009900"
//             color2="#00b200"
//             color3="#00cc00"
//           />
//           <XAxis
//             axisAt="bottom"
//             orient="bottom"
//             tickStroke={axisColor}
//             ticks={5}
//             zoomEnabled={false}
//             tickStroke={linesColor}
//             stroke={linesColor}
//           />
//           <AreaSeries
//             yAccessor={(d) => d.type == "buy" && d.volume}
//             fill="url(#buyGradient)"
//             canvasGradient={buyGradient}
//             strokeWidth={2}
//             stroke={COLOR_PALETTE.depthBuyGreen}
//             interpolation={curveStep}
//           />
//           <YAxis
//             stroke="#000000"
//             axisAt="left"
//             orient="right"
//             tickStroke={axisColor}
//             ticks={5}
//             yZoomWidth={0}
//             tickStroke={linesColor}
//             stroke={linesColor}
//           />
//           <HoverTooltip
//             tooltipContent={tooltipContent(currency)}
//             bgFill={linesColor}
//             stroke={linesColor}
//             fill="#ffffff"
//             bgOpacity={1}
//             fontFill="#000000"
//             opacity={1}
//             fontFamily={"inherit"}
//           />
//           <CurrentCoordinate
//             yAccessor={(d) => d.volume}
//             fill={linesColor}
//             r={5}
//             onHover
//           />
//         </Chart>
//       </ChartCanvas>
//       <ChartCanvas
//         ratio={1}
//         width={width / 2 - 10}
//         height={height}
//         margin={{ left: 0, right: 5, top: 5, bottom: 30 }}
//         seriesName="MSFT"
//         data={sellOrdersSorted}
//         type={type}
//         xAccessor={(d) => d.price}
//         panEvent={false}
//         seriesName={`MSFT_${this.state.suffix}`}
//         xExtents={xExtentsSell}
//         zoomEvent={false}
//         xScale={scaleLinear()}
//       >
//         <Chart
//           id={11}
//           // yExtents={d => d.volume}
//           yExtents={yAxisRange}
//           width={width / 2}
//         >
//           <Gradient
//             id="sellGradient"
//             color1="#cc0000"
//             color2="#b20000"
//             color3="#990000"
//           />
//           <XAxis
//             axisAt="bottom"
//             orient="bottom"
//             tickStroke={axisColor}
//             ticks={5}
//             zoomEnabled={false}
//             tickStroke={linesColor}
//             stroke={linesColor}
//           />
//           {/* empty axis in the middle of depth chart */}
//           <YAxis
//             stroke="#000000"
//             axisAt="left"
//             orient="right"
//             tickStroke={axisColor}
//             ticks={0}
//             yZoomWidth={0}
//             tickStroke={linesColor}
//             stroke={linesColor}
//           />
//           <YAxis
//             axisAt="right"
//             orient="left"
//             tickStroke={axisColor}
//             ticks={5}
//             yZoomWidth={0}
//             tickStroke={linesColor}
//             stroke={linesColor}
//           />
//           <AreaSeries
//             yAccessor={(d) => d.type == "sell" && d.volume}
//             canvasGradient={sellGradient}
//             fill="url(#sellGradient)"
//             stroke={COLOR_PALETTE.depthSellRed}
//             strokeWidth={2}
//             interpolation={curveStep}
//           />
//           <HoverTooltip
//             tooltipContent={tooltipContent(currency)}
//             bgFill={linesColor}
//             stroke={linesColor}
//             fill="#ffffff"
//             bgOpacity={1}
//             fontFill="#000000"
//             opacity={1}
//             fontFamily={"inherit"}
//           />
//           <CurrentCoordinate
//             yAccessor={(d) => d.volume}
//             fill={linesColor}
//             r={5}
//             onHover
//           />
//         </Chart>
//       </ChartCanvas>
//     </>

//     // <ChartCanvas
//     //   height={height}
//     //   ratio={ratio}
//     //   width={width}
//     //   margin={margin}
//     //   data={data}
//     //   displayXAccessor={displayXAccessor}
//     //   seriesName="Data"
//     //   xScale={xScale}
//     //   xAccessor={xAccessor}
//     //   xExtents={xExtents}
//     //   zoomAnchor={lastVisibleItemBasedZoomAnchor}
//     // >
//     //   <Chart
//     //     id={3}
//     //     height={chartHeight}
//     //     yExtents={candleChartExtents}
//     //     padding={{ top: 50, bottom: 5 }}
//     //   >
//     //     <XAxis showGridLines showTicks={false} showTickLabel={false} />
//     //     <YAxis showGridLines tickFormat={pricesDisplayFormat} />
//     //     <CandlestickSeries fill={openCloseColor} />
//     //     <MouseCoordinateY
//     //       rectWidth={margin.right}
//     //       displayFormat={pricesDisplayFormat}
//     //     />
//     //     <ZoomButtons />
//     //     <OHLCTooltip ohlcFormat={OHLCDisplayFormat} origin={[8, 16]} />
//     //   </Chart>
//     //   <Chart
//     //     id={2}
//     //     height={barChartHeight}
//     //     origin={barChartOrigin}
//     //     yExtents={barChartExtents}
//     //     padding={{ top: 8, bottom: 8 }}
//     //   >
//     //     <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" />
//     //     <YAxis ticks={4} tickFormat={pricesDisplayFormat} />

//     //     <MouseCoordinateX displayFormat={timeDisplayFormat} />
//     //     <MouseCoordinateY
//     //       rectWidth={margin.right}
//     //       displayFormat={tooltipDisplayFormat}
//     //     />

//     //     <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />

//     //     <SingleValueTooltip
//     //       yAccessor={volumeSeries}
//     //       yLabel="Volume"
//     //       yDisplayFormat={(volume: any) => {
//     //         return tooltipDisplayFormat(volume);
//     //       }}
//     //       origin={[8, 16]}
//     //     />
//     //   </Chart>
//     //   <CrossHairCursor />
//     // </ChartCanvas>
//   );
// }

export function TradingChart({ width, height, data }) {
  const [ratio, setRatio] = useState(1);

  useEffect(() => {
    const { devicePixelRatio } = window;
    setRatio(devicePixelRatio);
  }, [width, height]);

  if (!data.length) return null;
  return (
    <FinancialChart width={width} height={height} ratio={ratio} data={data} />
  );
}
