import { Progress } from "@mantine/core";
import { ReactNode, useMemo } from "react";
import { Column } from "react-table";
import { useMakeTable } from "@/components";
import { _numberFormatter } from "@/utils/lib";
import { MarketMonitoringDataType } from "@/sample/queries";

const topClientsByCountColumns: Array<
  Column<{
    cid: string;
    total_value: string;
    count: ReactNode;
  }>
> = [
  {
    Header: "Trader",
    accessor: "cid",
  },
  {
    Header: "Count",
    accessor: "count",
  },
  {
    Header: "Value",
    accessor: "total_value",
    Cell: ({ row }) => <>{_numberFormatter(row.original.total_value)}</>,
  },
];

export function TopClientsByCountTable({ value }) {
  function dataFilterer(
    rawData: MarketMonitoringDataType["top_clients_by_count"]
  ) {
    const count = { prev: 0 };
    return (
      rawData?.map((item) => {
        const { no_of_buys, no_of_sells, ...remainder } = item;
        const total_no = no_of_buys + no_of_sells;
        return {
          ...remainder,
          count: (
            <div className="flex items-center gap-3">
              <Progress
                className="w-20"
                sections={[
                  { value: (no_of_buys / total_no) * 100, color: "#DC372F" },
                  { value: (no_of_sells / total_no) * 100, color: "#32C973" },
                ]}
              />
              <span>{total_no}</span>
            </div>
          ),
        };
      }) ?? []
    );
  }

  const data = useMemo(() => dataFilterer(value), [value]);
  const columns = useMemo(() => topClientsByCountColumns, []);

  const { Table: TopClientsByCountTable } = useMakeTable({
    columns,
    data,
  });

  return (
    <TopClientsByCountTable className="h-full overflow-auto bg-ecn-light-red">
      <TopClientsByCountTable.Head className="sticky top-0 z-[5] bg-ecn-light-red text-ecn-dark" />
      <TopClientsByCountTable.Body className="overflow-auto text-ecn-light" />
    </TopClientsByCountTable>
  );
}
