import { Divider, Group, Stack, Text } from "@mantine/core";
import { _numberFormatter } from "@/utils/lib";
import { ArrowDown, ArrowUp, Chart, Clock } from "iconsax-react";
import { themeColor, TopPortion } from ".";

type PriceChangesProps = {
  change: {
    price: number;
    percentage_change: number;
  };
  high: {
    price: number;
    percentage_change: number;
  };
  low: {
    price: number;
    percentage_change: number;
  };
  volume: {
    price: number;
  };
  value: {
    price: number;
  };
};

export function PriceChanges({ details }: { details: PriceChangesProps }) {
  function ChangeDivider() {
    return <Divider orientation="vertical" className="my-auto h-1/2" />;
  }

  return (
    <Group>
      <Stack align="start" spacing={6}>
        <TopPortion Icon={Clock} title="24h change" />
        <Group className="text-sm" spacing="xs">
          <Text>{_numberFormatter(details.change.price)}</Text>
          <Text color={themeColor["ecn-mc-green"]}>
            {details.change.percentage_change}%
          </Text>
        </Group>
      </Stack>
      <ChangeDivider />
      <Stack align="start" spacing={6}>
        <TopPortion
          Icon={ArrowUp}
          color={themeColor["ecn-mc-green"]}
          title="24h high"
        />
        <Group className="text-sm" spacing="xs">
          <Text>{_numberFormatter(details.high.price)}</Text>
          <Text color={themeColor["ecn-mc-green"]}>
            {details.high.percentage_change}%
          </Text>
        </Group>
      </Stack>
      <ChangeDivider />
      <Stack align="start" spacing={6}>
        <TopPortion
          Icon={ArrowDown}
          color={themeColor["ecn-mc-red"]}
          title="24h low"
        />
        <Group className="text-sm" spacing="xs">
          <Text>{_numberFormatter(details.low.price)}</Text>
          <Text color={themeColor["ecn-mc-red"]}>
            {details.low.percentage_change}%
          </Text>
        </Group>
      </Stack>
      <ChangeDivider />
      <Stack align="start" spacing={6}>
        <TopPortion Icon={Chart} title="24h value" />
        <Group className="text-sm">
          <Text>{_numberFormatter(details.value.price, "")}</Text>
        </Group>
      </Stack>
      <ChangeDivider />
      <Stack align="start" spacing={6}>
        <TopPortion Icon={Chart} variant="Linear" title="24h volume" />
        <Group className="text-sm">
          <Text>{_numberFormatter(details.volume.price, "")}</Text>
        </Group>
      </Stack>
    </Group>
  );
}
