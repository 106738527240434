import { Column } from "react-table";
import { useMakeTable } from "@/components";
import { useMemo } from "react";
import { MarketMonitoringDataType } from "@/sample/queries";
import { _numberFormatter } from "@/utils/lib";

const topTradesByValueColumns: Array<
  Column<{
    order_type: string;
    total_volume: number;
    total_value: string;
    cid: string;
  }>
> = [
  {
    Header: "Trader",
    accessor: "cid",
  },
  {
    Header: "Volume",
    accessor: "total_volume",
    Cell: ({ row }) => (
      <span className="text-right">
        {_numberFormatter(row.original.total_volume)}
      </span>
    ),
  },
  {
    Header: "Order Type",
    accessor: "order_type",
  },
  {
    Header: "Value",
    accessor: "total_value",
    Cell: ({ row }) => <>{_numberFormatter(row.original.total_value)}</>,
  },
];

export function TopTradesByValueTable({ value }) {
  function dataFilterer(
    rawData: MarketMonitoringDataType["top_clients_by_value"]
  ) {
    return rawData ?? [];
  }

  const data = useMemo(() => dataFilterer(value), [value]);
  const columns = useMemo(() => topTradesByValueColumns, []);

  const { Table: TopTradesByValueTable } = useMakeTable({
    columns,
    data,
  });

  return (
    <TopTradesByValueTable className="h-full overflow-auto bg-ecn-light-red">
      <TopTradesByValueTable.Head className="sticky top-0 bg-ecn-light-red text-ecn-dark" />
      <TopTradesByValueTable.Body className="overflow-auto text-ecn-light" />
    </TopTradesByValueTable>
  );
}
