export * from "./securitiesMenu";
export * from "./buyOrdersTable";
export * from "./timeLine";
export * from "./orderBookMenu";
export * from "./contentWrapper";
export * from "./chartType";
export * from "./boardsMenu";
export * from "./headerSection";
export * from "./marketPriceDisplay";
export * from "./priceChanges";
export * from "./priceDisplay";
export * from "./priceFluctuation";
export * from "./sellOrdersTable";
export * from "./mainContent";
export * from "./marketDrawer";
export * from "./orderBook";
export * from "./securitiesMenu";
export * from "./skeletal";
export * from "./topCharts";
export * from "./topPortion";

// TABLES
export * from "./topClientsByCountTable";
export * from "./topTradesByValueTable";
export * from "./matchedTradesTable";
export * from "./withdrawalsTable";
export * from "./fundingsTable";

// ICONS
export * from "./buyAndSellTabIcon";
export * from "./buyTabIcon";
export * from "./sellTabIcon";

export const themeColor = {
  "ecn-mc-dim-red": "#F8F3F3",
  "ecn-mc-fresh-red": "#EA312C",
  "ecn-mc-bg-red": "#FAF8F8",
  "ecn-mc-accent-red": "#F9E3E3",
  "ecn-mc-light-red": "#FEFCFC",
  "ecn-mc-light-gray": "#FAFBFD",
  "ecn-mc-gray": "#EBEBEB",
  "ecn-mc-light": "#9A9A9A",
  "ecn-mc-dark": "#464646",
  "ecn-mc-green": "#32C973",
  "ecn-mc-red": "#DC372F",
};

export const currency = {
  naira: "₦",
};
