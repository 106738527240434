import { ActionIcon, Group, Text } from "@mantine/core";
import { ExportSquare } from "iconsax-react";
import { TopTradesByValueTable, TopClientsByCountTable, Skeletal } from ".";

export function TopCharts({ topTrades, topClients, isLoading }) {
  return (
    <article className="flex flex-wrap gap-2 mb-2">
      <section className="gap-2 grid grid-rows-[50px_250px] min-w-max flex-[1_1_50%]">
        <Group p="xs" position="apart" className="bg-ecn-light-red">
          <Text>Top Trades by Value</Text>
          <ActionIcon className="text-ecn-dark" size={20}>
            <ExportSquare variant="Linear" strokeWidth={2} />
          </ActionIcon>
        </Group>
        <Skeletal className="bg-ecn-light-red" visible={isLoading}>
          {
            <div className="grid content-start h-full overflow-auto bg-ecn-light-red">
              <TopTradesByValueTable value={topTrades} />
            </div>
          }
        </Skeletal>
      </section>
      <section className="gap-2 grid grid-rows-[50px_250px] min-w-max flex-[1_1_40%]">
        <Group p="xs" position="apart" className="bg-ecn-light-red">
          <Text>Top Clients by Count</Text>
          <ActionIcon className="text-ecn-dark" size={20}>
            <ExportSquare variant="Linear" />
          </ActionIcon>
        </Group>
        <Skeletal className="bg-ecn-light-red" visible={isLoading}>
          {
            <div className="grid content-start h-full overflow-auto bg-ecn-light-red">
              <TopClientsByCountTable value={topClients} />
            </div>
          }
        </Skeletal>
      </section>
    </article>
  );
}
