import { Stack, Text } from "@mantine/core";
import { _numberFormatter } from "@/utils/lib";
import { themeColor } from ".";

export function PriceDisplay({ title, price, volume, ...props }) {
  return (
    <Stack {...props} spacing={1}>
      <Text weight="bold" color={themeColor["ecn-mc-light"]} size={10}>
        {title}
      </Text>
      <Text weight="bold" size={12} color={themeColor["ecn-mc-dark"]}>
        {_numberFormatter(price)}
      </Text>
      <Text size={10} color={themeColor["ecn-mc-light"]}>
        {_numberFormatter(volume, "")} units
      </Text>
    </Stack>
  );
}
