import {
  MarketMonitoringDataType,
  SecurityMonitoringDataType,
} from "@/sample/queries";
import { ActionIcon } from "@mantine/core";
import { _numberFormatter } from "@/utils/lib";
import { ArrowUp, TransmitSquare, ExportSquare } from "iconsax-react";
import { Dispatch, SetStateAction } from "react";
import { BuyOrdersTable } from "./buyOrdersTable";
import { VolumeChart } from "./financialChart";
import { SellOrdersTable } from "./sellOrdersTable";

interface OrderBookProps {
  orders: string;
  marketInfo?: MarketMonitoringDataType;
  securityInfo?: SecurityMonitoringDataType;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export function OrderBook({
  orders,
  marketInfo,
  securityInfo,
  setDrawerOpen,
}: OrderBookProps) {
  function DrawerControl() {
    return (
      <div
        onClick={() => {
          setDrawerOpen((o) => !o);
        }}
        className="flex items-center gap-3 px-5 py-2 ml-auto text-white cursor-pointer rounded-tl-xl bg-accent-90 group"
      >
        <p>Expand</p>
        <ActionIcon className="text-current group-hover:text-ecn-light-red group-hover:bg-transparent">
          <TransmitSquare variant="Broken" />
        </ActionIcon>
      </div>
    );
  }

  if (orders === "Buy") {
    return (
      <>
        <BuyOrdersTable value={marketInfo?.buy_orders ?? []} />
        <DrawerControl />
      </>
    );
  } else if (orders === "Sell") {
    return (
      <>
        <SellOrdersTable value={marketInfo?.sell_orders ?? []} />
        <DrawerControl />
      </>
    );
  } else if (orders === "All") {
    return (
      <>
        {/* <VolumeChart
          initialData={marketInfo?.all_orders?.map(({ created, units }) => ({
            date: dayjs(created).toDate(),
            volume: units,
          })) ?? []}
          width={300}
          ratio={1}
        /> */}
        <BuyOrdersTable value={marketInfo?.buy_orders} />
        <div className="flex items-center justify-between px-3 py-2 bg-ecn-light-red">
          <div className="flex gap-2 text-ecn-fresh-green">
            <h3 className="text-md">
              {_numberFormatter(securityInfo?.latest_price ?? 0)}
            </h3>
            <ArrowUp />
          </div>
          <ActionIcon size={24}>
            <ExportSquare variant="Linear" />
          </ActionIcon>
        </div>

        <SellOrdersTable value={marketInfo?.sell_orders ?? []} />
        <DrawerControl />
      </>
    );
  } else
    return (
      <div className="grid content-start overflow-auto bg-ecn-light-red" />
    );
}
